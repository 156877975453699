import axios from 'axios'
import qs from "qs";
import { getLocalStorageStr } from './common'
import { apiUrl, sourceUrl } from './config'


const instance = axios.create({
    baseURL: apiUrl,
    timeout: 10000
})

export const get = async (url, data = {}) => {
    let res = await initRequestHeader('get', url, data);
    data = res.data;
    return new Promise((resolve, reject) => {
        instance.get(res.url, { params: data, headers: res.headers }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                // window.location.href = sourceUrl + 'user/login?redirect=' + encodeURIComponent(des_url);
                // window.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

export const post = async (url, data = {}, type = 'urlencoded') => {
    let res = await initRequestHeader('post', url, data, type);
    return new Promise((resolve, reject) => {
        instance.post(res.url, res.data, {
            headers: res.headers
        }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                // window.location.href = sourceUrl + 'user/login?redirect=' + encodeURIComponent(des_url);
                // window.location.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

/**
 * 初始化请求头和数据
 * @zjf-2020-12-25
 */
const initRequestHeader = async (method, url, data = {}, type = 'urlencoded') => {
    let result = {};
    let headers = {};

    //用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
    if (getLocalStorageStr('sld_refresh_token')) {
        let cur_time = new Date().getTime();
        if (!getLocalStorageStr('time')||(getLocalStorageStr('time')&&(cur_time - getLocalStorageStr('time') > 58 * 60 * 1000))) {
            let params = {
                'grant_type': 'refresh_token',
                'refresh_token': getLocalStorageStr('sld_refresh_token'),
            };
            await instance.post('v3/adminLogin/admin/oauth/token', qs.stringify(params), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then((response) => {
                if (response.data.state == 200) {
                    localStorage.setItem('sld_token', response.data.data.access_token);
                    localStorage.setItem('sld_refresh_token', response.data.data.refresh_token);
                    localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                }
            }, err => {
                console.log('更新access_token出错：', err);
            })
        }
    }

    if (method == 'post') {
        if (type == 'urlencoded') {
            headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            data = qs.stringify(data);
        } else if (type == 'json') {
            headers = { 'Content-Type': 'application/json' };
            data = JSON.parse(JSON.stringify(data));
        } else if (type == 'form') {//form-data
            headers = { 'Content-Type': 'multipart/form-data' };
            let tmp_data = new FormData();
            Object.keys(data).forEach((item) => {
                tmp_data.append(item, data[item])
            });
            data = tmp_data;
        }
    }

    if (url.indexOf('v3/adminLogin/admin/oauth/token') > -1) {
        headers.Authorization = 'Basic c2VsbGVyOnNlbGxlcg==';
    } else {
        let token = getLocalStorageStr('sld_token');
        // let token = 'eyJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNSIsImV4cCI6MTYzNzAzNDE0OCwidXVpZCI6Ijg4MWY1MDIwLTE1ZjUtNDRlYS1hZGM4LWU3NTNlZTE5OGM2MSIsIndlYklkZW50aWZ5IjoiYWRtaW4ifQ.COGVZimIKbUPe9V-NnL-ngCMjLj5faUCa73bxAVy_NglE9lzCnctltqgY3nd3kMJObkYLokufN5lN9ZiXKQXk4MeDuAV29BrTKk5W7aFFCQvXwSncnnw2t6zXysbZ2bNdC-nVhroJ9XSBLX_nnKL5auE_Rbc2Rwp3RZ9Sq3nxM-2RvTq-C501mSGqntWLZI9XiCuirmGPQJubYG8grms8sPUUAV84MxgxuT3XjaGPCbN9wQJ3tEG5wE_9fZnCf19Gm0eSY3Lqj9hMx5NPpurevagjqIqhbLqSu3irVjz3vLP8DEQm2mqMS_5-Tfe7sEyyka5S2j76_KpC1DwqL-Wfg'
        headers.Authorization = 'Bearer ' + token;

    }

    result = { url, data, headers };
    return result;
}
