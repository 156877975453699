/**
 *  项目的配置文件
 */
export const apiUrl = 'https://test-im.qingfeifeicui.com/'//接口请求地址
export const chatUrl = 'wss://test-im.qingfeifeicui.com'//客服地址
export const sourceUrl = 'https://test-admin.qingfeifeicui.com/';//来源地址，当用户失去登录态时需跳转到来源登录页
export const pcUrl = 'https://test-pc.qingfeifeicui.com/'//没有PC终端，这里无需配置，直接留空即可
export const imageUrl = 'https://test-image.qingfeifeicui.com'

// export const apiUrl = 'https://kf.qingfeifeicui.com/'//接口请求地址
// export const chatUrl = 'wss://kf.qingfeifeicui.com'//客服地址
// export const sourceUrl = 'https://admin.qingfeifeicui.com/';//来源地址，当用户失去登录态时需跳转到来源登录页
// export const pcUrl = ''//没有PC终端，这里无需配置，直接留空即可
// export const imageUrl = 'https://image.qingfeifeicui.com/' // 图片地址

export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** qffc *** version-v2.7 *** date-2023-05-18 ***主版本v2.7**/
