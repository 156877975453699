import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { chatUrl } from './utils/config'
import 'normalize.css'
import './style/index.scss'
import { get, post } from './utils/request'
import {
  getLocalStorageStr, getCurLanguage, goGoodsDetail, goStoreDetail, checkMobile, checkEmailCode, checkPwd, checkSmsCode,
  checkMemberName, checkImgCode, addCart, diyNavTo, sldCommonTip, checkEmail, getUserInfo, cartDataNum, getLoginCartListData, calculateSubtotal, goGoodsListByCatId, sldLlineRtextAddGoodsAddMargin, getSldHorLine, formatChatTime, isShowTime, getQueryVariable, setQueryVar
} from './utils/common';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import VueSocketIO from "vue-socket.io";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);

//VueSocketIO的install方法的参数vue的protoType是vue2的，重写一下把vue3的config.globalProperties赋给prototype
//就不会报'cannot set properties of undefined setting $socket'的错误
const olderInstall = VueSocketIO.prototype.install
VueSocketIO.prototype.install = function (Vue) {
  Vue.prototype = Vue.config.globalProperties
  olderInstall.apply(this, [Vue])
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


// 配置全局属性
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$getLocalStorageStr = getLocalStorageStr;
app.config.globalProperties.$getCurLanguage = getCurLanguage;
app.config.globalProperties.$goGoodsDetail = goGoodsDetail;
app.config.globalProperties.$goStoreDetail = goStoreDetail;
app.config.globalProperties.$checkMobile = checkMobile;
app.config.globalProperties.$checkPwd = checkPwd;
app.config.globalProperties.$checkSmsCode = checkSmsCode;
app.config.globalProperties.$checkMemberName = checkMemberName;
app.config.globalProperties.$checkImgCode = checkImgCode;
app.config.globalProperties.$addCart = addCart;
app.config.globalProperties.$diyNavTo = diyNavTo;
app.config.globalProperties.$sldCommonTip = sldCommonTip;
app.config.globalProperties.$checkEmail = checkEmail;
app.config.globalProperties.$getUserInfo = getUserInfo;
app.config.globalProperties.$cartDataNum = cartDataNum;
app.config.globalProperties.$getLoginCartListData = getLoginCartListData;
app.config.globalProperties.$calculateSubtotal = calculateSubtotal;
app.config.globalProperties.$checkEmailCode = checkEmailCode;
app.config.globalProperties.$goGoodsListByCatId = goGoodsListByCatId;
app.config.globalProperties.$sldLlineRtextAddGoodsAddMargin = sldLlineRtextAddGoodsAddMargin;
app.config.globalProperties.$getSldHorLine = getSldHorLine;
app.config.globalProperties.$formatChatTime = formatChatTime;
app.config.globalProperties.$isShowTime = isShowTime;
app.use(ElementPlus, { locale });
app.use(new VueSocketIO({
  debug: false,
  connection: chatUrl,
}))


app.use(router).use(store).mount('#app')



setQueryVar()


router.beforeEach(() => {
  window.scrollTo(0, 0)
})




